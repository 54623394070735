import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as routes from '../../../../constants/routes';
import { analytics } from '../../../../actions';

const FailedScreen = (props) => {
  const { classes, staticText } = props;

  const tryAgain = () => {
    // Reset state.
    props.resetState();
  };

  const supportClicked = () => {
    // Track Support Clicked event.
    analytics.track('Support Clicked', {
      context: {
        app: {
          name: 'VerifyApp',
          namespace: 'components/Verification/Verify',
          build: process.env.REACT_APP_BUILD,
          version: process.env.REACT_APP_VERSION,
        },
      },
      widgetPage: routes.MANUAL_VERIFICATION.replace('/', ''),
    });
    // Redirect to Contact Us page.
    props.contactUs();
  };

  return (
    <React.Fragment>
      <Typography variant="h1" className={classes.h1} component="h1">
        {staticText.ManualVerificationFailTitle}
      </Typography>
      <Typography variant="h2" className={classes.h2} component="h2">
        {staticText.ManualVerificationFailSubTitle}
      </Typography>
      <div className={classes.centerContent + ' ' + classes.alignContentTop}>
        <Button
          className={classes.button}
          disableElevation
          onClick={tryAgain}
          fullWidth
          size="large"
        >
          {staticText.ManualVerificationFailBtn}
        </Button>
        <Button
          className={classes.buttonOutlined}
          disableElevation
          onClick={supportClicked}
          fullWidth
          size="large"
        >
          {staticText.ManualVerificationFailBtnHelp}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default FailedScreen;
