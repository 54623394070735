import { firestoreHelper, functionsHelper } from '../firebase/firebase';
const { collection, db, doc, getDoc, getDocs, onSnapshot } = firestoreHelper;
const { functions, httpsCallable } = functionsHelper;
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_CREATE,
  FETCH_BRAND_INFO,
  FETCH_CONFIG,
  FETCH_MILINFO,
  FETCH_BRANCHESINFO,
  FETCH_QUIZ,
  FETCH_QUIZ_ERROR,
  FETCH_PLANS,
  FETCH_STATIC_TEXT,
  QUIZ_ANSWERS,
  QUIZ_RESET,
  SESSION_DB_ID,
  SESSION_DB_UPDATE,
  SET_ERROR_PAGE,
} from '../constants/actiontypes';
import * as Sentry from '@sentry/browser';

export const onSetAuthUser = (authUser) => (dispatch) => {
  dispatch({
    type: 'AUTH_USER_SET',
    authUser,
  });

  // If user logs out, reset accountData.
  if (!authUser) {
    dispatch({
      type: 'FETCH_ACCOUNT',
      payload: null,
    });
  }
};

// Generate unique session ID.
export const createSessionId = (existingSessionId) => (dispatch) => {
  function uniqueID() {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }

    return (
      chr4() +
      chr4() +
      '-' +
      chr4() +
      '-' +
      chr4() +
      '-' +
      chr4() +
      '-' +
      chr4() +
      chr4() +
      chr4()
    );
  }

  const sessionId = existingSessionId ? existingSessionId : uniqueID();

  dispatch({
    type: SESSION_DB_ID,
    payload: sessionId,
  });
};

// Update/Write session into the Store.
export const setSessionId = (id) => (dispatch) => {
  dispatch({
    type: SESSION_DB_ID,
    payload: id,
  });
};
// Update/Write session into the Store.
export const updateSession = (updates) => (dispatch) => {
  dispatch({
    type: SESSION_DB_UPDATE,
    payload: updates,
  });
};

// Get all the fields in the user document from DB.
export const fetchAccountInfo = (uid) => (dispatch) => {
  const document = doc(db, 'veterans', uid);
  onSnapshot(document, (snapshot) => {
    if (snapshot.exists) {
      dispatch({
        type: FETCH_ACCOUNT,
        payload: snapshot.data(),
      });
    } else {
      dispatch({
        type: FETCH_ACCOUNT_CREATE,
        payload: true,
      });
    }
  });
};

// Get Military Info.
export const fetchMilitaryInfo = () => (dispatch) => {
  const colMilitaryBranches = collection(db, 'terms_military_branches');
  getDocs(colMilitaryBranches)
    .then((querySnapshot) => {
      const branches = [];
      querySnapshot.forEach((doc) => {
        branches.push({ ...doc.data(), id: doc.id });
      });
      if (branches.length === 0) {
        dispatch({
          type: FETCH_MILINFO,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCH_MILINFO,
          payload: { branches },
        });
      }
    })
    .then(() => {
      const colMilitaryRanks = collection(db, 'terms_military_ranks');
      return getDocs(colMilitaryRanks);
    })
    .then((querySnapshot) => {
      const ranks = [];
      querySnapshot.forEach((doc) => {
        ranks.push({ ...doc.data(), id: doc.id });
      });

      if (ranks.length === 0) {
        dispatch({
          type: FETCH_MILINFO,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCH_MILINFO,
          payload: { ranks },
        });
      }
    })
    .then(() => {
      const colServiceEras = collection(db, 'terms_service_eras');
      return getDocs(colServiceEras);
    })
    .then((querySnapshot) => {
      const serviceEras = [];
      querySnapshot.forEach((doc) => {
        serviceEras.push({ ...doc.data(), id: doc.id });
      });
      if (serviceEras.length === 0) {
        dispatch({
          type: FETCH_MILINFO,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCH_MILINFO,
          payload: { eras: serviceEras },
        });
      }
    })
    .then(() => {
      const colPaygrades = collection(db, 'terms_paygrades');
      return getDocs(colPaygrades);
    })
    .then((querySnapshot) => {
      const paygrades = [];
      querySnapshot.forEach((doc) => {
        paygrades.push({ ...doc.data(), id: doc.id });
      });
      if (paygrades.length === 0) {
        dispatch({
          type: FETCH_MILINFO,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCH_MILINFO,
          payload: { paygrades },
        });
      }
    })
    .then(() => {
      const docMilitaryData = doc(db, 'VerifyAppStaticText', 'militaryData');
      return getDoc(docMilitaryData);
    })
    .then((snapshot) => {
      let queryData = snapshot.data();
      dispatch({
        type: FETCH_MILINFO,
        payload: {
          units: queryData.unit_list,
          relationships: queryData.relationship_list,
        },
      });
    });
};

// Get Branches Info.
export const fetchBranchesInfo = () => (dispatch) => {
  const colBranches = collection(db, 'terms_military_branches');
  getDocs(colBranches).then((querySnapshot) => {
    const branches = [];
    querySnapshot.forEach((doc) => {
      branches.push({ ...doc.data(), id: doc.id });
    });
    if (branches.length === 0) {
      dispatch({
        type: FETCH_BRANCHESINFO,
        payload: false,
      });
    } else {
      dispatch({
        type: FETCH_BRANCHESINFO,
        payload: { branches },
      });
    }
  });
};

// Get brand filtered by brand name,
export const fetchBrandInfo = (providerToken, brandUUID) => (dispatch) => {
  const fetchBrandWithUUID = (uuid) => {
    const docBrand = doc(db, 'nodes_brand', uuid);
    return onSnapshot(docBrand, (querySnapshot) => {
      if (!querySnapshot.exists) {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: false,
        });
      } else {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: querySnapshot?.data(),
        });
      }
    });
  };

  // Fetch Brand from providerToken.
  if (providerToken) {
    const docAccessDomain = doc(db, 'accessDomain', providerToken);
    getDoc(docAccessDomain)
      .then((querySnapshot) => {
        if (!querySnapshot.exists) {
          dispatch({
            type: FETCH_BRAND_INFO,
            payload: false,
          });
        } else {
          return querySnapshot?.data()?.brandUuid;
        }
      })
      .then((brandId) => {
        if (brandId) {
          fetchBrandWithUUID(brandId);
        } else {
          dispatch({
            type: FETCH_BRAND_INFO,
            payload: false,
          });
        }
      });
  }
  // Fetch Brand from brandUUID.
  else if (brandUUID) {
    fetchBrandWithUUID(brandUUID);
  }
};

// Get all the static texts for the site.
export const fetchStaticTexts = () => (dispatch) => {
  const doctTexts = doc(db, 'VerifyAppStaticText', 'textsWesalute');
  getDoc(doctTexts).then((doc) => {
    if (doc.exists) {
      dispatch({
        type: FETCH_STATIC_TEXT,
        payload: doc.data(),
      });
    } else {
      dispatch({
        type: FETCH_STATIC_TEXT,
        payload: false,
      });
    }
  });
};

// Get the DB config collection.
export const fetchConfig = () => (dispatch) => {
  const docConfigVaUser = doc(db, 'config', 'va_user');
  onSnapshot(docConfigVaUser, (doc) => {
    if (doc.exists) {
      dispatch({
        type: FETCH_CONFIG,
        payload: doc.data(),
      });
    } else {
      dispatch({
        type: FETCH_CONFIG,
        payload: false,
      });
    }
  });
};

// Get the quizes.
export const fetchQuiz = (branch) => (dispatch) => {
  dispatch({
    type: QUIZ_RESET,
  });

  const getQuizes = httpsCallable(
    functions,
    'groupVerifyVerification-getMilitaryQuestions'
  );
  const configData = {
    branch: branch,
  };
  getQuizes(configData)
    .then((result) => {
      dispatch({
        type: FETCH_QUIZ,
        payload: result.data,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error(error);

      dispatch({
        type: FETCH_QUIZ_ERROR,
        payload: error,
      });
    });
};

// Dispatch the chosen answers.
export const chooseAnswers = (answers) => (dispatch) => {
  dispatch({
    type: QUIZ_ANSWERS,
    payload: answers,
  });
};

// Create id verification session.
export const doCreateIdVerificationSession = () => (dispatch) => {
  const externalIdVerificationCreateSession = httpsCallable(
    functions,
    'groupVerifyVeriff-externalIdVerificationCreateSession'
  );

  externalIdVerificationCreateSession().then((resp) => {
    // Update session with session url.
    if (
      resp?.data?.status === 'success' &&
      typeof resp.data.verification !== 'undefined' &&
      typeof resp.data.verification.id !== 'undefined' &&
      resp.data.verification.id !== '' &&
      typeof resp.data.verification.url !== 'undefined' &&
      resp.data.verification.url !== ''
    ) {
      dispatch({
        type: SESSION_DB_UPDATE,
        payload: {
          verificationIdSessionUrl: resp.data.verification.url,
          verificationIdSessionId: resp.data.verification.id,
        },
      });
    }
  });
};

// Get all the avaiable plans.
export const fetchPlans = () => (dispatch) => {
  const colPlans = collection(db, 'nodes_plan');
  getDocs(colPlans).then((querySnapshot) => {
    const plans = [];
    querySnapshot.forEach((doc) => {
      plans.push(doc.data());
    });
    if (plans.length === 0) {
      dispatch({
        type: FETCH_PLANS,
        payload: false,
      });
    } else {
      dispatch({
        type: FETCH_PLANS,
        payload: plans,
      });
    }
  });
};

// Dispatch the error page.
export const setErrorPage =
  (
    alertTitle,
    alertDesc,
    alertType,
    description,
    buttonTitle,
    buttonPage,
    buttonExternal,
    buttonClose,
    buttonSignOut,
    descriptionTitle
  ) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_PAGE,
      payload: {
        alert: {
          title: alertTitle ? alertTitle : false,
          desc: alertDesc ? alertDesc : false,
          type: alertType ? alertType : false,
        },
        desc: description ? description : false,
        descTitle: descriptionTitle ? descriptionTitle : false,
        btn: {
          title: buttonTitle ? buttonTitle : false,
          page: buttonPage ? buttonPage : false,
          external: buttonExternal ? buttonExternal : false,
          close: buttonClose ? buttonClose : false,
          signout: buttonSignOut ? buttonSignOut : false,
        },
        active: true,
      },
    });
  };

// Clear the error page.
export const clearErrorPage = () => (dispatch) => {
  dispatch({
    type: SET_ERROR_PAGE,
    payload: {
      alert: {
        title: false,
        desc: false,
        type: false,
      },
      desc: false,
      descTitle: false,
      btn: {
        title: false,
        page: false,
        external: false,
        close: false,
        signout: false,
      },
      active: false,
    },
  });
};
