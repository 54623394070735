import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  signOut,
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  EmailAuthProvider,
  RecaptchaVerifier,
  PhoneAuthProvider,
  linkWithPhoneNumber,
  updatePhoneNumber,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  limit,
  doc,
  getDoc,
  getDocs,
  orderBy,
  onSnapshot,
  startAfter,
  where,
  Timestamp,
  FieldValue,
  runTransaction,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const app = initializeApp(config);

const phone_provider = PhoneAuthProvider.PROVIDER_ID;
const authHelper = {
  auth: getAuth(app),
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  signOut,
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  phone_provider,
  linkWithPhoneNumber,
  PhoneAuthProvider,
  updatePhoneNumber,
};

const functionsHelper = {
  functions: getFunctions(app),
  httpsCallable,
};

// Storage helpers.
const storageHelper = {
  storage: getStorage(app),
  ref,
  uploadBytes,
  deleteObject,
};

const firestoreHelper = {
  db: getFirestore(app),
  collection,
  query,
  limit,
  orderBy,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  startAfter,
  where,
  runTransaction,
  FieldValue,
  updateDoc,
  setDoc,
};
const timestamp = Timestamp;

const recaptcha = (elm, config) => {
  return new RecaptchaVerifier(authHelper.auth, elm, config);
};

const emailAuth = (email, password) =>
  EmailAuthProvider.credential(email, password);

export {
  app,
  authHelper,
  emailAuth,
  firestoreHelper,
  functionsHelper,
  recaptcha,
  storageHelper,
  timestamp,
};
