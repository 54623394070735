import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as routes from '../../../constants/routes';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { styles } from '../../../styles';
import Preloader from '../../Preloader';
import LighthouseVerificationForm from './LighthouseVerificationForm';
import { db, helpers } from '../../../actions';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import { vh } from '../../../styles/helper';
import { saveIdentification } from '../../../actions/helpers';
import getCustomRoute from '../../../actions/getCustomRoute';
import { fetchAccountInfo } from '../../../actions/db';

const LighthouseVerification = () => {
  const { fetchBranchesInfo } = db;

  const history = useHistory();
  const dispatch = useDispatch();

  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const staticTexts = useSelector((state) => state.textsState.data);
  const branchesInfoData = useSelector((state) => state.branchesInfoState.data);

  const [branch, setBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [fieldData, setFieldData] = useState({
    street: '',
    city: '',
    zipcode: '',
    state: null,
  });

  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    ...styles.backButtonStyles,
    boxHeigher: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        paddingTop: vh('52px'),
        paddingBottom: vh('20px'),
        height: 'auto',
      },
    },
  }));

  const classes = useStyles();

  const handleChange = (name, value) => {
    setFieldData((prevState) => ({
      ...prevState,
      [name]: name === 'zipcode' ? value.replace(/[^\d-]/g, '') : value,
    }));
  };

  // On change branch.
  const onChangeBranch = (event, option) => {
    setBranch(option);
  };

  // Skip Lighthouse Verification check and go to full veteran registration.
  const fullVeteranRegistration = () => {
    helpers
      .addAccountInfo(
        {
          flags: {
            showMilCred: true,
          },
        },
        authUser.uid
      )
      .then(() => {
        // Trigger Lighthouse confirmation Api Skipped event.
        return saveIdentification({ type: 'lighthouse', status: 'failed' });
      })
      .then(() => {
        // Load latest accountData with updated affinity.
        dispatch(fetchAccountInfo(authUser.uid));
        return true;
      })
      .then(() => {
        getCustomRoute(history);
      })
      .catch((error) => {
        helpers.handleError(error, dispatch);
      });
  };

  // On Lighthouse Verification form submit.
  const onSubmit = () => {
    setLoading(true);

    helpers
      .doVerifyMilitaryStatus({
        gender: accountData.gender,
        firstName: accountData.firstname,
        lastName: accountData.lastname,
        zipCode: fieldData.zipcode,
        city: fieldData.city,
        streetAddressLine1: fieldData.street,
        birthDate: dateOfBirth,
        state: fieldData.state.id,
        country: 'US',
      })
      .then((result) => {
        setLoading(false);
        const updateAccountData = {
          branch: branch.value,
          shippingAddress: {
            address: {
              address_line1: fieldData.street,
              administrative_area: fieldData.state.id,
              country_code: 'US',
              postal_code: fieldData.zipcode,
              locality: fieldData.city,
            },
          },
        };
        if (result.data) {
          helpers
            .addAccountInfo(updateAccountData, authUser.uid)
            .then(() => {
              getCustomRoute(history);
            })
            .catch((error) => {
              helpers.handleError(error, dispatch);
            });
        } else {
          helpers
            .addAccountInfo(
              {
                flags: {
                  showMilCred: true,
                },
              },
              authUser.uid
            )
            .then(() => {
              history.push(routes.MILITARY_CREDENTIALS);
            })
            .catch((error) => {
              helpers.handleError(error, dispatch);
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        helpers.handleError(error, dispatch);
      });
  };

  useEffect(() => {
    if (authUser) {
      // Get static branches data.
      if (branchesInfoData && branchesInfoData.branches) {
        // Sort helper function.
        function weightSorter(a, b) {
          return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        }

        // List of branches.
        let branchesArr = [];
        branchesInfoData.branches.forEach(applyBranchListFormating);

        function applyBranchListFormating(obj) {
          branchesArr.push({
            value: obj.id,
            label: obj.name,
            weight: obj.weight,
          });
        }

        branchesArr.sort(weightSorter);

        setBranches(branchesArr);
      } else if (!branchesInfoData.branches) {
        dispatch(fetchBranchesInfo());
      }
    }
    // eslint-disable-next-line
  }, [authUser, branchesInfoData])

  // Set fields if defined.
  useEffect(() => {
    if (accountData) {
      if (accountData.branch && branches[0]) {
        setBranch(branches.find((x) => x.value === accountData.branch));
      }

      if (accountData.dateOfBirth) {
        //  Check type of field and fill it.
        if (typeof accountData.dateOfBirth === 'string') {
          setDateOfBirth(new Date(accountData.dateOfBirth));
        } else if (typeof accountData.dateOfBirth === 'object') {
          setDateOfBirth(new Date(accountData.dateOfBirth.toDate()));
        }
      }
    }
  }, [branches, accountData]);

  const isFormValid = () => {
    return (
      branch &&
      fieldData.street &&
      fieldData.city &&
      fieldData.zipcode &&
      fieldData.state
    );
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      {(loading || !branchesInfoData.branches) && (
        <Preloader errorTitle="Error loading SSN Verification page" />
      )}
      <>
        <IconButton
          aria-label="back"
          className={classes.back}
          onClick={fullVeteranRegistration}
          data-id="back-btn"
        >
          <ArrowBackIosIcon fontSize="medium" />
        </IconButton>
        <LighthouseVerificationForm
          branch={branch}
          branches={branches}
          onChangeBranch={onChangeBranch}
          staticTexts={staticTexts}
          onSubmit={onSubmit}
          skip={fullVeteranRegistration}
          fieldData={fieldData}
          onChange={handleChange}
          isFormValid={isFormValid}
        />
      </>
    </Box>
  );
};

export default LighthouseVerification;
