import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db, helpers } from '../../../actions';
import { useHistory } from 'react-router-dom';
import Preloader from '../../Preloader';
import * as routes from '../../../constants/routes';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import VeteranCredentials from './VeteranCredentials';
import VeteranServiceHistory from './VeteranServiceHistory';
import ActiveCredentials from './ActiveCredentials';
import ActiveServiceHistory from './ActiveServiceHistory';
import RelativeInfo from './RelativeInfo';
import RelativeCredentials from './RelativeCredentials';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BackButton from '../../Common/BackButton';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { vh } from '../../../styles/helper';
import { styles } from '../../../styles';
import { timestamp } from '../../../firebase/firebase';
import getCustomRoute, {
  getAffinity,
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import dayjs from 'dayjs';

const MilitaryCredentials = () => {
  const { setErrorPage, updateSession, fetchMilitaryInfo } = db;

  const history = useHistory();
  const dispatch = useDispatch();

  const accountData = useSelector((state) => state.accountState.account);
  const accountDataStatus = useSelector((state) => state.accountState.status);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const sessionDB = useSelector((state) => state.sessionDBState);
  const militaryInfoData = useSelector((state) => state.militaryInfoState.data);
  const editMode = useSelector(
    (state) => state.sessionDBState.data.urlQuery.edit
  );
  const configState = useSelector((state) => state.configState);
  const useStyles = makeStyles((theme) => ({
    ...styles.defaultFormStyles,
    ...styles.backButtonStyles,
    boxHeigher: {
      ['@media (min-width:' + theme.breakpoints.values.md + 'px)']: {
        paddingTop: vh('50px'),
        paddingBottom: vh('50px'),
      },
    },
  }));
  const staticText = useSelector((state) => state.textsState.data);

  const classes = useStyles();

  const [startDate, setStartDate] = useState('1992-08-22');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dischargeDate, setDischargeDate] = useState();
  const [relativeDateOfBirth, setRelativeDateOfBirth] = useState();
  const [loading, setLoading] = useState(true);
  const [affinity, setAffinity] = useState('');
  const [branch, setBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [timeServed, setTimeServed] = useState(null);
  const [timesServed, setTimesServed] = useState(null);
  const [affidavit, setAffidavit] = useState('');
  const [error, setError] = useState(null);
  const [infoFilled, setInfoFilled] = useState(false);
  const [relativeFirstName, setRelativeFirstName] = useState('');
  const [relativeLastName, setRelativeLastName] = useState('');
  const [signature, setSignature] = useState(null);
  const [relativeData, setRelativeData] = useState(true);
  const [isEnabled, setIsEnabled] = useState(true);
  const [step, setStep] = useState(1);
  const [fieldsError, setFieldsError] = useState({});
  const [accountDataFieldsProcess, setAccountDataFieldsProcess] =
    useState(false);
  const [disableNextStep, setDisableNextStep] = useState(false);

  const [data, setData] = useState({
    rank: null,
    paygrade: null,
    unit: null,
    relationship: null,
    ranks: [],
    paygrades: [],
    units: [],
    relationships: [],
  });

  /**
   * Populate state variables with Redux global data.
   */
  useEffect(() => {
    if (
      configState.status !== 'loading' &&
      accountDataStatus === 'loaded' &&
      militaryInfoData &&
      militaryInfoData.branches &&
      militaryInfoData.eras &&
      militaryInfoData.ranks &&
      militaryInfoData.paygrades &&
      militaryInfoData.units &&
      militaryInfoData.relationships
    ) {
      // Sort helper function.
      function weightSorter(a, b) {
        return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
      }

      // List of branches.
      let branchesArr = [];
      militaryInfoData.branches.forEach(applyBranchListFormating);

      function applyBranchListFormating(obj) {
        branchesArr.push({
          value: obj.id,
          label: obj.name,
          weight: obj.weight,
          domain: obj?.domain,
        });
      }

      const affinity = getAffinity(accountData);

      if (affinity === 'commissioned') {
        branchesArr = branchesArr.filter((branchObj) =>
          configState.config.commissionedBranches.includes(branchObj.value)
        );
      } else if (affinity === 'academy') {
        branchesArr = branchesArr.filter((branchObj) => branchObj.domain);
      }
      branchesArr.sort(weightSorter);

      setBranches(branchesArr);

      // List times served.
      let timesServedArr = [];
      militaryInfoData.eras.forEach(applyErasListFormating);

      function applyErasListFormating(obj) {
        timesServedArr.push({
          value: obj.id,
          label: obj.name,
          weight: obj.weight,
        });
      }

      timesServedArr.sort(weightSorter);

      setTimesServed(timesServedArr);

      // List of ranks.
      let ranks = [];

      militaryInfoData.ranks.forEach(applyRanksListFormating);

      function applyRanksListFormating(obj) {
        ranks.push({
          value: obj.id,
          label: obj.name,
          weight: obj.weight,
        });
      }

      ranks.sort(weightSorter);

      // List of paygrades.
      let paygrades = [];

      militaryInfoData.paygrades.forEach(applyPaygradesListFormating);

      function applyPaygradesListFormating(obj) {
        paygrades.push({
          value: obj.id,
          label: obj.name,
          weight: obj.weight,
        });
      }

      paygrades.sort(weightSorter);

      // List of units.
      const units = militaryInfoData.units.map((arraydata) => {
        return { value: arraydata, label: arraydata };
      });

      const relationship = militaryInfoData.relationships.map((arraydata) => {
        return { value: arraydata, label: arraydata };
      });

      setData({
        ...data,
        ranks: ranks,
        paygrades: paygrades,
        units: units,
        relationships: relationship,
      });
    } else if (!militaryInfoData.branches) {
      dispatch(fetchMilitaryInfo());
    }
    // eslint-disable-next-line
  }, [militaryInfoData, configState, accountDataStatus])

  useEffect(() => {
    if (branches && timesServed && !accountDataFieldsProcess) {
      const { lighthouseStatus } = getAllIdentificationStatuses(accountData);
      const milAffinity = getAffinity(accountData);
      if (
        editMode &&
        (editMode === 'true' || editMode === '1' || editMode === 1)
      ) {
        // If edit mode with academy, redirect back to site as no information can be changed.
        if (milAffinity === 'academy') {
          dispatch(
            setErrorPage(
              staticText.AcademyEditModeTitle,
              false,
              'info',
              staticText.AcademyEditModeDesc,
              'Go Back',
              routes.VERIFY,
              false,
              false,
              false
            )
          );
        }

        setLoading(false);
      } else if (accountData?.flags?.showMilCred) {
        helpers
          .addAccountInfo(
            {
              flags: {
                showMilCred: false,
              },
            },
            authUser.uid
          )
          .catch((error) => {
            helpers.handleError(error, dispatch);
          });

        if (sessionDB.data.milCredState) {
          setDateOfBirth(sessionDB.data.milCredState.dateOfBirth);
          setRelativeDateOfBirth(
            sessionDB.data.milCredState.relativeDateOfBirth
          );
          setAffinity(sessionDB.data.milCredState.affinity);
          setBranch(sessionDB.data.milCredState.branch);
          setTimeServed(sessionDB.data.milCredState.timeServed);
          setAffidavit(sessionDB.data.milCredState.affidavit);
          setError(sessionDB.data.milCredState.error);
          setInfoFilled(sessionDB.data.milCredState.infoFilled);
          setRelativeFirstName(sessionDB.data.milCredState.relativeFirstName);
          setRelativeLastName(sessionDB.data.milCredState.relativeLastName);
          setRelativeData(sessionDB.data.milCredState.relativeData);
          setData(sessionDB.data.milCredState.data);
        }

        setLoading(false);
      } else if (
        accountData?.branch &&
        milAffinity &&
        // If academy or relative.
        ((milAffinity !== 'active_duty' &&
          milAffinity !== 'commissioned' &&
          milAffinity !== 'veteran') ||
          // Or active_duty, commissioned and veterans with populated rank, paygrade and unit.
          ((milAffinity === 'active_duty' ||
            milAffinity === 'commissioned' ||
            milAffinity === 'veteran') &&
            accountData?.rank &&
            accountData?.paygrade &&
            accountData?.unit)) &&
        (milAffinity === 'academy' ||
          milAffinity === 'active_duty' ||
          milAffinity === 'commissioned' ||
          accountData?.eraServed)
      ) {
        let relativeDataSet = true;

        if (
          milAffinity === 'relative' &&
          (typeof accountData.militaryRelative === 'undefined' ||
            !accountData.militaryRelative ||
            Object.keys(accountData.militaryRelative).length === 0)
        ) {
          relativeDataSet = false;
        }

        // Add branch to the session.
        dispatch(
          updateSession(
            {
              branch: accountData.branch,
              affinity: milAffinity,
            },
            sessionDB.id
          )
        );

        // Set the flag that we have the info already filled, so ask only for birth date.
        setInfoFilled(true);

        // If we have the date of birth also filled, then go to next step.
        if (relativeDataSet && accountData?.dateOfBirth) {
          // Helper to handle all routes logic.
          getCustomRoute(history);
        } else {
          setRelativeData(relativeDataSet);
          setLoading(false);
        }
      }
      // If veteran already have all needed fields then pass to the next step.
      else if (
        milAffinity === 'veteran' &&
        lighthouseStatus &&
        accountData.branch &&
        accountData.paygrade &&
        accountData.rank &&
        accountData.dateOfBirth
      ) {
        getCustomRoute(history);
      } else {
        setLoading(false);
      }

      // Relative setup.
      if (accountData?.militaryRelative?.firstName) {
        setRelativeFirstName(accountData.militaryRelative.firstName);
      }
      if (accountData?.militaryRelative?.lastName) {
        setRelativeLastName(accountData.militaryRelative.lastName);
      }
      if (accountData?.militaryRelative?.branch) {
        setBranch(
          branches.find((x) => x.value === accountData.militaryRelative.branch)
        );
      }
      if (accountData?.militaryRelative?.eraServed) {
        setTimeServed(
          timesServed.find(
            (x) => x.value === accountData.militaryRelative.eraServed
          )
        );
      }
      if (accountData?.militaryRelative?.dateOfBirth) {
        setRelativeDateOfBirth(
          dayjs(accountData.militaryRelative.dateOfBirth, 'YYYY-MM-DD')
        );
      }

      // Set normal fields.
      if (accountData.dateOfBirth) {
        //  Check type of field and fill it.
        if (typeof accountData.dateOfBirth === 'string') {
          setStartDate(accountData.dateOfBirth);
          setDateOfBirth(accountData.dateOfBirth);
        } else if (accountData.dateOfBirth instanceof timestamp) {
          setStartDate(helpers.dateToString(accountData.dateOfBirth.toDate()));
          setDateOfBirth(
            helpers.dateToString(accountData.dateOfBirth.toDate())
          );
        }
      }
      if (accountData.signature) {
        setSignature(accountData.signature);
      }
      if (milAffinity) {
        setAffinity(milAffinity);
      }
      if (accountData.affidavit) {
        setAffidavit(true);
      }
      if (accountData.branch) {
        setBranch(branches.find((x) => x.value === accountData.branch));
      }

      let dataUpdate = { ...data };

      if (accountData.rank) {
        dataUpdate.rank = data.ranks.find((x) => x.value === accountData.rank);
      }
      if (accountData.paygrade) {
        dataUpdate.paygrade = data.paygrades.find(
          (x) => x.value === accountData.paygrade
        );
      }
      if (accountData.unit) {
        dataUpdate.unit = data.units.find((x) => x.value === accountData.unit);
      }
      if (accountData.relationship) {
        dataUpdate.relationship = data.relationships.find(
          (x) => x.value === accountData.relationship
        );
      }
      setData(dataUpdate);

      if (accountData.eraServed) {
        setTimeServed(
          timesServed.find((x) => x.value === accountData.eraServed)
        );
      }

      if (accountData.dischargeDate) {
        setDischargeDate(dayjs(accountData.dischargeDate, 'YYYY-MM-DD'));
      }

      setAccountDataFieldsProcess(true);
    }
    // eslint-disable-next-line
  }, [branches, data.ranks, accountDataFieldsProcess, timesServed, authUser, accountData?.relationship])

  useEffect(() => {
    if (
      // Enable if step 2 and affinity veteran and branch, timeServed and dischargeDate.
      (step === 1 &&
        affinity === 'veteran' &&
        branch &&
        timeServed &&
        dischargeDate) ||
      // Enable if step 2 and affinity active_duty and branch.
      (step === 1 &&
        (affinity === 'active_duty' ||
          affinity === 'academy' ||
          affinity === 'commissioned') &&
        branch) ||
      // Enable if step 2 and affinity relative and relativeFirstName, relativeLastName and relativeDateOfBirth.
      (step === 1 &&
        affinity === 'relative' &&
        relativeFirstName &&
        relativeLastName &&
        relativeDateOfBirth) ||
      // Enable if step 3 and affinity relative and timeServed and branch.
      (step === 2 &&
        affinity === 'relative' &&
        branch &&
        timeServed &&
        data.relationship) ||
      // Enable if step 3 and affinity veteran and rank, paygrade, unit.
      (step === 2 &&
        affinity === 'veteran' &&
        data.rank &&
        data.paygrade &&
        data.unit) ||
      // Enable if step 3 and affinity active_duty and rank, paygrade, unit.
      (step === 2 &&
        (affinity === 'active_duty' || affinity === 'commissioned') &&
        data.rank &&
        data.paygrade &&
        data.unit)
    ) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  }, [
    step,
    affinity,
    branch,
    timeServed,
    dischargeDate,
    relativeFirstName,
    relativeLastName,
    relativeDateOfBirth,
    data.rank,
    data.paygrade,
    data.unit,
    data.relationship,
    dateOfBirth,
    signature,
  ]);

  const handleDischargeDateChange = (value, context) => {
    // Check if the date is valid.
    if (context.validationError === null && value) {
      setDischargeDate(value);
    } else if (!value) {
      setDischargeDate('');
    }
  };

  const handleBranchChange = (event, option) => {
    setBranch(option);
  };

  const handleTimeServedChange = (event, option) => {
    setTimeServed(option);
  };

  const nextStep = () => {
    if (
      (step === 1 && affinity === 'academy') ||
      (step > 1 && affinity !== 'academy')
    ) {
      onSubmit();
    } else {
      setStep(step + 1);
      setIsEnabled(false);
    }
  };

  const goBackStep = () => {
    setStep(step - 1);
    setIsEnabled(false);
  };

  const handleRelativeDateChange = (value, context) => {
    // Check if the date is valid.
    if (context.validationError === null && value) {
      setRelativeDateOfBirth(value);
    } else if (!value) {
      setRelativeDateOfBirth('');
    }
  };

  const handleOptionChangeRank = (event, option) => {
    setData({
      ...data,
      rank: option,
    });
  };

  const handleOptionChangePaygrade = (event, option) => {
    setData({
      ...data,
      paygrade: option,
    });
  };

  const handleOptionChangeUnit = (event, option) => {
    setData({
      ...data,
      unit: option,
    });
  };

  const handleOptionChangeRelationship = (event, option) => {
    setData({
      ...data,
      relationship: option,
    });
  };

  const redirect = (accountUpdates) => {
    if (
      editMode &&
      (editMode === 'true' || editMode === '1' || editMode === 1)
    ) {
      history.push(routes.VERIFY);
    } else {
      getCustomRoute(history, accountUpdates);
    }
  };

  const inputValidation = (e, name, notEmpty) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = '@field is required'.replace('@field', fieldName);

    if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const onSubmit = async () => {
    const { rank, paygrade, unit, relationship } = data;

    let sessionUpdates = {
      milCredState: {
        dateOfBirth,
        relativeDateOfBirth,
        startDate,
        affinity,
        branch,
        timeServed,
        affidavit,
        error,
        infoFilled,
        relativeFirstName,
        relativeLastName,
        relativeData,
        data,
        signature,
      },
    };
    let accountUpdates = {
      affidavit: true,
      dateOfBirth: dateOfBirth,
      signature: signature,
    };

    if (infoFilled && !relativeData) {
      accountUpdates = {
        ...accountUpdates,
        militaryRelative: {
          firstName: relativeFirstName,
          lastName: relativeLastName,
          branch: branch.value,
          eraServed: timeServed.value,
          dateOfBirth: relativeDateOfBirth.format('YYYY-MM-DD'),
        },
        relationship: relationship.value,
      };
    } else {
      if (
        affinity === 'veteran' ||
        affinity === 'active_duty' ||
        affinity === 'commissioned'
      ) {
        accountUpdates = {
          ...accountUpdates,
          branch: branch.value,
          signature: signature,
          rank: rank.value,
          paygrade: paygrade.value,
          unit: unit.value,
        };

        if (affinity === 'veteran') {
          accountUpdates = {
            ...accountUpdates,
            affinity: affinity,
            eraServed: timeServed.value,
            dischargeDate: dischargeDate.format('YYYY-MM-DD'),
          };
        }
        sessionUpdates = {
          ...sessionUpdates,
          branch: branch.value,
          affinity: affinity,
        };
      } else if (affinity === 'academy') {
        accountUpdates = {
          ...accountUpdates,
          branch: branch.value,
        };
        sessionUpdates = {
          ...sessionUpdates,
          affinity: affinity,
        };
      } else {
        accountUpdates = {
          ...accountUpdates,
          affinity: affinity,
          branch: branch.value,
          eraServed: timeServed.value,
          militaryRelative: {
            firstName: relativeFirstName,
            lastName: relativeLastName,
            branch: branch.value,
            eraServed: timeServed.value,
            dateOfBirth: relativeDateOfBirth.format('YYYY-MM-DD'),
          },
          relationship: relationship.value,
        };
        sessionUpdates = {
          ...sessionUpdates,
          branch: branch.value,
          affinity: affinity,
        };
      }
    }

    // Update session with needed data.
    dispatch(updateSession(sessionUpdates));
    // Update DB User record with needed data.
    setDisableNextStep(true);
    await helpers
      .addAccountInfo(accountUpdates, authUser.uid)
      .then(() => {
        setDisableNextStep(false);
        redirect({ ...accountData, ...accountUpdates });
      })
      .catch((error) => {
        setDisableNextStep(false);
        setIsEnabled(true);
        helpers.handleError(error, dispatch);
      });
  };

  const onChangeRelativeFirstName = (event) => {
    return setRelativeFirstName(event.target.value.substring(0, 60));
  };

  const onChangeRelativeLastName = (event) => {
    return setRelativeLastName(event.target.value.substring(0, 60));
  };

  if (loading || !militaryInfoData.branches || !militaryInfoData.eras) {
    return (
      <Preloader
        title={staticText.MilitaryCredentialsLoading}
        errorTitle="Error loading Military Credentials page"
      />
    );
  } else {
    return (
      <Box
        p={{ xs: 2, md: 10 }}
        boxShadow={{ xs: 0, sm: 0, md: 6 }}
        className={
          classes.box +
          ' ' +
          // Set smaller padding for these specific pages.
          (((affinity === 'veteran' ||
            affinity === 'active_duty' ||
            affinity === 'commissioned') &&
            step === 2) ||
          (step === 1 && affinity === 'academy')
            ? classes.boxHeigher
            : '')
        }
      >
        {step === 1 && !editMode && <BackButton />}

        <div className={'header'}>
          {step !== 1 && (
            <IconButton
              aria-label="back"
              disabled={disableNextStep}
              className={classes.back}
              onClick={goBackStep}
              data-id="back-btn"
            >
              <ArrowBackIosIcon fontSize="medium" />
            </IconButton>
          )}
          {/*
        TITLE
        */}
          {(step === 1 || (affinity === 'relative' && step > 1)) && (
            <Typography variant="h1" component="h1">
              {staticText.MilitaryCredentialsTitle}
            </Typography>
          )}

          {(affinity === 'veteran' ||
            affinity === 'active_duty' ||
            affinity === 'commissioned') &&
            step > 1 && (
              <Typography variant="h1" component="h1">
                {staticText.ServiceHistoryTitle}
              </Typography>
            )}

          {/*SUBTITLE*/}

          {affinity === 'veteran' && step === 1 && (
            <Typography variant="h2" component="h2">
              {staticText.ServiceHistorySubTitleActive}
            </Typography>
          )}
          {(affinity === 'veteran' ||
            affinity === 'active_duty' ||
            affinity === 'commissioned') &&
            step > 1 && (
              <Typography variant="h2" component="h2">
                {staticText.ServiceHistorySubTitleVeteran}
              </Typography>
            )}
          {affinity === 'relative' && (step === 1 || step > 1) && (
            <Typography variant="h2" component="h2">
              {staticText.ServiceHistorySubTitleRelative}
            </Typography>
          )}
        </div>

        <div className={classes.centerContent}>
          {/*VETERAN STEPS*/}
          {affinity === 'veteran' && step === 1 && (
            <VeteranCredentials
              branch={branch}
              branches={branches}
              timeServed={timeServed}
              timesServed={timesServed}
              handleBranchChange={handleBranchChange}
              handleTimeServedChange={handleTimeServedChange}
              dischargeDate={dischargeDate}
              handleDischargeDateChange={handleDischargeDateChange}
              staticText={staticText}
            />
          )}
          {affinity === 'veteran' && step > 1 && (
            <VeteranServiceHistory
              rank={data.rank}
              paygrade={data.paygrade}
              unit={data.unit}
              ranks={data.ranks}
              paygrades={data.paygrades}
              units={data.units}
              onSubmit={onSubmit}
              handleOptionChangeRank={handleOptionChangeRank}
              handleOptionChangePaygrade={handleOptionChangePaygrade}
              handleOptionChangeUnit={handleOptionChangeUnit}
              staticText={staticText}
            />
          )}

          {/*ACTIVE DUTY STEPS*/}
          {(affinity === 'active_duty' ||
            affinity === 'academy' ||
            affinity === 'commissioned') &&
            step === 1 && (
              <ActiveCredentials
                branch={branch}
                branches={branches}
                handleBranchChange={handleBranchChange}
                staticText={staticText}
              />
            )}
          {(affinity === 'active_duty' || affinity === 'commissioned') &&
            step > 1 && (
              <ActiveServiceHistory
                rank={data.rank}
                paygrade={data.paygrade}
                unit={data.unit}
                ranks={data.ranks}
                paygrades={data.paygrades}
                units={data.units}
                onSubmit={onSubmit}
                handleOptionChangeRank={handleOptionChangeRank}
                handleOptionChangePaygrade={handleOptionChangePaygrade}
                handleOptionChangeUnit={handleOptionChangeUnit}
                staticText={staticText}
              />
            )}

          {/*RELATIVE STEPS*/}
          {affinity === 'relative' && step === 1 && (
            <RelativeInfo
              inputValidation={inputValidation}
              fieldsError={fieldsError}
              firstname={relativeFirstName}
              onChangeFirstName={onChangeRelativeFirstName}
              lastname={relativeLastName}
              onChangeLastName={onChangeRelativeLastName}
              relativeDateOfBirth={relativeDateOfBirth}
              handleRelativeDateChange={handleRelativeDateChange}
              staticText={staticText}
            />
          )}

          {affinity === 'relative' && step > 1 && (
            <RelativeCredentials
              branch={branch}
              branches={branches}
              timeServed={timeServed}
              timesServed={timesServed}
              relationship={data.relationship}
              relationships={data.relationships}
              handleBranchChange={handleBranchChange}
              handleTimeServedChange={handleTimeServedChange}
              handleOptionChangeRelationship={handleOptionChangeRelationship}
              staticText={staticText}
            />
          )}
        </div>

        <div className={'footer'}>
          <Button
            className={classes.button}
            type="submit"
            disableElevation
            onClick={nextStep}
            disabled={!isEnabled || disableNextStep}
            fullWidth
            size="large"
            data-id="military-credentials-next"
          >
            {staticText.MilitaryCredentialsBtn}
          </Button>
        </div>
      </Box>
    );
  }
};

export default MilitaryCredentials;
