import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db, helpers } from '../../../actions';
import { useHistory } from 'react-router-dom';
import CommissionedForm from './CommissionedForm';
import CommissionedCodeForm from './CommissionedCodeForm';
import Preloader from '../../Preloader';
import BackButton from '../../Common/BackButton';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { styles } from '../../../styles';
import getCustomRoute, {
  getAffinity,
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import { saveIdentification } from '../../../actions/helpers';
import { fetchAccountInfo } from '../../../actions/db';

const styleOverride = {};

const Commissioned = () => {
  const { fetchMilitaryInfo, updateSession } = db;

  const history = useHistory();
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const sessionDB = useSelector((state) => state.sessionDBState);
  const staticText = useSelector((state) => state.textsState.data);
  const militaryInfoData = useSelector((state) => state.militaryInfoState.data);
  const configState = useSelector((state) => state.configState);

  const customStyles = {
    ...styles.defaultFormStyles,
    ...styles.backButtonStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [codeVisible, setCodeVisible] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [era, setEra] = useState('');

  useEffect(() => {
    if (configState.status !== 'loading') {
      setEmailList(configState.config.commissionedWhitelist);
    }
  }, [configState]);

  useEffect(() => {
    if (
      configState.status !== 'loading' &&
      militaryInfoData &&
      militaryInfoData.eras &&
      militaryInfoData.branches
    ) {
      // Sort helper function.
      function weightSorter(a, b) {
        return parseInt(a.weight, 10) - parseInt(b.weight, 10);
      }

      // List of branches.
      let branchesArr = [];
      militaryInfoData.branches.forEach(applyBranchListFormating);
      function applyBranchListFormating(obj) {
        branchesArr.push({
          value: obj.id,
          label: obj.name,
          weight: obj.weight,
        });
      }

      branchesArr.sort(weightSorter);

      let filteredBranches = branchesArr.filter((branchObj) =>
        configState.config.commissionedBranches.includes(branchObj.value)
      );

      setBranches(filteredBranches);

      // Set era with weight 0 for active duty roles.
      militaryInfoData.eras.forEach((obj) => {
        if (obj.weight === 0) setEra(obj.id);
      });
    } else if (!militaryInfoData.branches) {
      dispatch(fetchMilitaryInfo());
    }
    // eslint-disable-next-line
  }, [militaryInfoData, configState])

  useEffect(() => {
    // Redirect user once he is verified.
    if (accountData) {
      const { emailStatus } = getAllIdentificationStatuses(accountData);
      if (['passed', 'failed'].indexOf(emailStatus) !== -1) {
        setLoading(false);
        getCustomRoute(history);
      }
    }
  }, [accountData]);

  const onSubmitEmail = (event) => {
    setLoading(true);
    let branchData = {};

    branchData.branch = accountData.branch ?? branch?.value;

    // Add branch to the session.
    dispatch(
      updateSession(
        {
          affinity: getAffinity(accountData),
          eraServed: era,
          ...branchData,
        },
        sessionDB.id
      )
    );

    helpers
      .writeSession(
        {
          affinity: getAffinity(accountData),
          militaryEmail: email,
          eraServed: era,
          ...branchData,
        },
        sessionDB.id
      )
      .then(() => {
        return helpers.addAccountInfo(
          {
            ...branchData,
            eraServed: era,
          },
          authUser.uid
        );
      })
      .then(() => {
        return helpers.sendEmailCode(sessionDB.id, getAffinity(accountData));
      })
      .then(() => {
        return saveIdentification({
          type: 'email',
          email: email,
          status: 'pending',
        }).catch((error) => {
          console.error(error.message);
        });
      })
      .then(() => {
        // Load latest accountData with updated affinity.
        dispatch(fetchAccountInfo(authUser.uid));
        return true;
      })
      .then(() => {
        setLoading(false);
        setCodeVisible(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError({
          message: error?.message ? error?.message : 'Sending email failed.',
        });
      });

    event.preventDefault();
  };

  const onSubmitCode = (event) => {
    event.preventDefault();
    setLoading(true);

    helpers
      .verifyEmailCode(sessionDB.id, code)
      .then(() => {
        saveIdentification({
          type: 'email',
          email: email,
          status: 'pending',
        }).catch((error) => {
          console.error(error.message);
        });
      })
      .catch(() => {
        setLoading(false);
        setError(new Error('Code verification failed.'));
      })
      .catch(() => {
        setLoading(false);
        setError(new Error('Code verification failed.'));
      });
  };

  const doValidateEmail = (option) => {
    let pattern = '.gov';
    let allowedEmails = pattern
      .replace(/\./g, '\\.')
      .replace('*', '[a-z0-9][-a-z0-9\\.]*[a-z0-9]');

    emailList.forEach((allowEmail) => {
      let emailItem = allowEmail;

      emailItem = emailItem
        .replace(/\./g, '\\.')
        .replace('*', '[a-z0-9][-a-z0-9\\.]*[a-z0-9]');
      allowedEmails += '|' + emailItem;
    });

    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regAllowEmails = RegExp(
      '^[A-Za-z0-9._%+-]+@(' + allowedEmails + ')$'
    );

    if (reg.test(String(option).toLowerCase())) {
      if (regAllowEmails.test(String(option).toLowerCase())) {
        setError(null);
      } else {
        setError(
          new Error(
            'We accept only military emails from our pre-approved list.'
          )
        );
      }
    } else {
      setError(new Error('Not a valid email.'));
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Put email in lowercase and limit the first and last names to 60 characters.
    if (fieldName === 'email') {
      setEmail(fieldValue.toLowerCase());
    } else if (fieldName === 'code') {
      setCode(fieldValue.replace(/ /g, ''));
    }
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticText.CommissionedErrorRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setError(new Error(fieldNameIsRequired));
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setError(new Error(fieldNameIsRequired));
    } else {
      setError(null);
    }
  };

  const onSkip = () => {
    saveIdentification({ type: 'email', email: email, status: 'failed' })
      .then(() => {
        getCustomRoute(history);
      })
      .catch((error) => {
        helpers.handleError(error, dispatch);
      });
  };

  const handleBranchChange = (event, option) => {
    setBranch(option);
  };

  const resendCode = () => {
    setCode('');
    setLoading(false);
    setCodeVisible(false);
    setError(null);
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      {!codeVisible && <BackButton />}

      {codeVisible && (
        <IconButton
          aria-label="back"
          className={classes.back}
          onClick={resendCode}
          data-id="back-btn"
        >
          <ArrowBackIosIcon fontSize="medium" />
        </IconButton>
      )}

      {loading && (
        <Preloader
          title={staticText.CommissionedBranchEmailTitle}
          errorTitle="Error loading Commissioned page"
        />
      )}

      {!codeVisible && (
        <CommissionedForm
          accountData={accountData}
          branch={branch}
          branches={branches}
          handleBranchChange={handleBranchChange}
          onSkip={onSkip}
          onSubmit={onSubmitEmail}
          email={email}
          onChangeInput={onChangeInput}
          inputValidation={inputValidation}
          error={error}
          staticText={staticText}
        />
      )}

      {codeVisible && (
        <CommissionedCodeForm
          resendCode={resendCode}
          onSubmitCode={onSubmitCode}
          onSkip={onSkip}
          onChangeInput={onChangeInput}
          inputValidation={inputValidation}
          code={code}
          error={error}
          email={email}
          staticText={staticText}
        />
      )}
    </Box>
  );
};

export default Commissioned;
