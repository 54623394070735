import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { db, helpers } from '../../../actions';
import { useHistory } from 'react-router-dom';
import { styles } from '../../../styles';
import AcademyForm from './AcademyForm';
import AcademyCodeForm from './AcademyCodeForm';
import Preloader from '../../Preloader';
import BackButton from '../../Common/BackButton';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import getCustomRoute, {
  getAffinity,
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import { saveIdentification } from '../../../actions/helpers';
import { fetchAccountInfo } from '../../../actions/db';

const styleOverride = {};

const Academy = () => {
  const { fetchMilitaryInfo, updateSession } = db;

  const history = useHistory();
  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const sessionDB = useSelector((state) => state.sessionDBState);
  const staticText = useSelector((state) => state.textsState.data);
  const reverifyMode = useSelector(
    (state) => state.sessionDBState.data.urlQuery.reverify
  );
  const militaryInfoData = useSelector((state) => state.militaryInfoState.data);
  const customStyles = {
    ...styles.defaultFormStyles,
    ...styles.backButtonStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [codeVisible, setCodeVisible] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [era, setEra] = useState('');
  const configState = useSelector((state) => state.configState);

  useEffect(() => {
    if (configState.status !== 'loading') {
      setEmailList(configState.config.activeDutyWhitelist);
    }
  }, [configState]);

  useEffect(() => {
    if (militaryInfoData?.eras) {
      // Set era with weight 0 for active duty roles.
      militaryInfoData.eras.forEach((obj) => {
        if (obj.weight === 0) setEra(obj.id);
      });
    } else if (!militaryInfoData.eras) {
      dispatch(fetchMilitaryInfo());
    }
    // eslint-disable-next-line
  }, [militaryInfoData])

  useEffect(() => {
    // Redirect user once he is verified.
    if (accountData) {
      const { emailStatus } = getAllIdentificationStatuses(accountData);
      if (['passed', 'failed'].indexOf(emailStatus) !== -1) {
        setLoading(false);
        getCustomRoute(history);
      }
    }
  }, [accountData]);

  const onSubmitEmail = (event) => {
    event.preventDefault();

    setLoading(true);

    return helpers
      .getBranchFromDomain(emailDomain)
      .then((branchId) => {
        dispatch(
          updateSession({
            branch: branchId,
            eraServed: era,
          })
        );

        return helpers.addAccountInfo(
          {
            branch: branchId,
            eraServed: era,
          },
          authUser.uid
        );
      })
      .then(() => {
        return helpers.writeSession(
          {
            militaryEmail: email,
            eraServed: era,
          },
          sessionDB.id
        );
      })
      .then(() => {
        return helpers.sendEmailCode(sessionDB.id, getAffinity(accountData));
      })
      .then(() => {
        return saveIdentification({
          type: 'email',
          email: email,
          status: 'pending',
        }).catch((error) => {
          console.error(error.message);
        });
      })
      .then(() => {
        // Load latest accountData with updated affinity.
        dispatch(fetchAccountInfo(authUser.uid));
        return true;
      })
      .then(() => {
        setLoading(false);
        setCodeVisible(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError({
          message: error?.message ? error?.message : 'Sending email failed.',
        });
      });
  };

  const onSubmitCode = (event) => {
    event.preventDefault();

    setLoading(true);

    helpers.verifyEmailCode(sessionDB.id, code).catch(() => {
      setLoading(false);
      setError(new Error('Code verification failed.'));
    });
  };

  const doValidateEmail = (option) => {
    let pattern = '.edu';
    let allowedEmails = pattern
      .replace(/\./g, '\\.')
      .replace('*', '[a-z0-9][-a-z0-9\\.]*[a-z0-9]');

    emailList.forEach((allowEmail) => {
      let emailItem = allowEmail;

      emailItem = emailItem
        .replace(/\./g, '\\.')
        .replace('*', '[a-z0-9][-a-z0-9\\.]*[a-z0-9]');
      allowedEmails += '|' + emailItem;
    });

    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regAllowEmails = RegExp(
      '^[A-Za-z0-9._%+-]+@(' + allowedEmails + ')$'
    );

    if (reg.test(String(option).toLowerCase())) {
      if (regAllowEmails.test(String(option).toLowerCase())) {
        setError(null);
      } else {
        setError(
          new Error(
            'We accept only "' +
              pattern +
              '" emails from our pre-approved list.'
          )
        );
      }
    } else {
      setError(new Error('Not a valid email.'));
    }
    // Set the email.
    setEmail(option);
    setEmailDomain(option.split('@')[1]);
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Put email in lowercase and limit the first and last names to 60 characters.
    if (fieldName === 'email') {
      setEmail(fieldValue.toLowerCase());
    } else if (fieldName === 'code') {
      setCode(fieldValue.replace(/ /g, ''));
    }
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticText.AcademyErrorRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setError(new Error(fieldNameIsRequired));
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setError(new Error(fieldNameIsRequired));
    } else {
      setError(null);
    }
  };

  const onSkip = () => {
    saveIdentification({ type: 'email', email: email, status: 'failed' })
      .then(() => {
        getCustomRoute(history);
      })
      .catch((error) => {
        helpers.handleError(error, dispatch);
      });
  };

  const resendCode = () => {
    setCode('');
    setLoading(false);
    setCodeVisible(false);
    setError(null);
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      {!reverifyMode && !codeVisible && <BackButton />}

      {codeVisible && (
        <IconButton
          aria-label="back"
          className={classes.back}
          onClick={resendCode}
          data-id="back-btn"
        >
          <ArrowBackIosIcon fontSize="medium" />
        </IconButton>
      )}

      {loading && (
        <Preloader
          title={staticText.AcademyLoader}
          errorTitle="Error loading Academy page"
        />
      )}

      {!codeVisible && (
        <AcademyForm
          onSkip={onSkip}
          onSubmit={onSubmitEmail}
          email={email}
          onChangeInput={onChangeInput}
          inputValidation={inputValidation}
          error={error}
          staticText={staticText}
        />
      )}

      {codeVisible && (
        <AcademyCodeForm
          resendCode={resendCode}
          onSubmitCode={onSubmitCode}
          onSkip={onSkip}
          onChangeInput={onChangeInput}
          inputValidation={inputValidation}
          code={code}
          error={error}
          email={email}
          staticText={staticText}
        />
      )}
    </Box>
  );
};

export default Academy;
