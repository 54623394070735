import store from '../store';
import * as Sentry from '@sentry/browser';
import { VASegmentTracking } from '@wesalute/lib-segmentio-js';

/**
 * Common init method.
 *
 * @returns {null}
 */
const init = () => {
  // Get redux store state.
  const state = store.getState();
  // Get memberId.
  const memberId = state?.accountState?.account?.memberId ?? null;
  return new VASegmentTracking('VerifyApp', memberId);
};

/**
 * Segment load helper.
 *
 * @returns {*}
 */
export const load = (segmentKey) => {
  try {
    return VASegmentTracking.load(segmentKey);
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

/**
 * Helper wrapper for common Segment page() method.
 *
 * @param string name
 * @param object properties
 * @returns {VASegmentTracking.page}
 */
export const page = (name, properties) => {
  try {
    const vaAnalytics = init();
    return vaAnalytics.page(name, properties);
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

/**
 * Helper wrapper for common Segment track() method.
 *
 * @param string name
 * @param object properties
 * @returns {VASegmentTracking.track}
 */
export const track = (name, properties) => {
  try {
    const vaAnalytics = init();
    return vaAnalytics.track(name, properties);
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

/**
 * Common identify processing wrapper.
 *
 * @returns {*}
 */
export const identify = () => {
  try {
    const vaAnalytics = init();
    return vaAnalytics.identify();
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};

/**
 * Common identify processing wrapper.
 *
 * @returns {*}
 */
export const reset = () => {
  try {
    return VASegmentTracking.reset();
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
  }
};
