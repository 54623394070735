import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { helpers, db } from '../../actions';
import Preloader from '../Preloader';
import * as firebase from '../../firebase/firebase';

import getCustomRoute, {
  getAllIdentificationStatuses,
  phoneVerificationEnabled,
  signOutHelper,
} from '../../actions/getCustomRoute';
import { addAccountInfo, calculateTrustScore } from '../../actions/helpers';
import {
  createSessionId,
  fetchAccountInfo,
  setErrorPage,
  updateSession,
} from '../../actions/db';

import useCustomLogin from '@wesalute/lib-auth-client/lib/useCustomLogin';

const Sign = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Verify App Redirect helper.
   */
  const accountData = useSelector((state) => state.accountState.account);
  const configData = useSelector((state) => state.configState.config);
  const configDataStatus = useSelector((state) => state.configState.status);
  const reverifyMode = useSelector(
    (state) => state.sessionDBState.data.urlQuery.reverify
  );
  const signOutUser = useSelector(
    (state) => state.sessionDBState.data.signOutUser
  );
  const staticTexts = useSelector((state) => state.textsState.data);

  const [authUser] = useCustomLogin(firebase.authHelper, signOutHelper);

  // TODO remove once phone verification
  // will be finally adopted in VerifyApp.
  useEffect(() => {
    // Try to calculate phone number trustScore if phone verification disabled in VerifyApp.
    if (!phoneVerificationEnabled) {
      if (accountData?.phone && accountData?.phoneVerified) {
        const { proveStatus } = getAllIdentificationStatuses(accountData);
        if (proveStatus !== 'pending' || proveStatus !== 'failed') {
          calculateTrustScore().catch((error) => console.error(error));
        }
      }
    }
  }, [accountData]);

  useEffect(() => {
    if (configDataStatus === 'loaded') {
      // If a user is logged in already, redirect to next step.
      if (authUser) {
        // Populate redux authUser data.
        dispatch(db.onSetAuthUser(authUser.toJSON()));
        dispatch(db.fetchAccountInfo(authUser.uid));
        dispatch(updateSession({ populateSession: true }), createSessionId());
        // Move user to needed page.
        if (accountData) {
          if (!reverifyMode || (reverifyMode && accountData?.flags?.reverify)) {
            // Check HH account data.
            checkHouseholdMembers(accountData);
          }
        }
      } else if (!authUser) {
        dispatch(db.onSetAuthUser(null));
      }
    }

    // eslint-disable-next-line
  }, [authUser, accountData, signOutUser])

  // Set user as re-verified.
  useEffect(() => {
    if (reverifyMode && authUser?.uid) {
      addAccountInfo(
        {
          flags: {
            reverify: true,
          },
        },
        authUser?.uid
      )
        .then(() => {
          dispatch(fetchAccountInfo(authUser?.uid));
        })
        .catch((error) => {
          helpers.handleError(error, dispatch);
        });
    }
  }, [reverifyMode, authUser]);

  const checkHouseholdMembers = (accountData) => {
    if (
      accountData.roles &&
      (accountData.roles.includes('vr_household') ||
        accountData.roles.includes('va_household'))
    ) {
      dispatch(
        setErrorPage(
          staticTexts.HHMembersWidgetAlertTitle,
          staticTexts.HHMembersWidgetAlertDesc,
          'warning',
          false,
          staticTexts.HHMembersWidgetAlertBtn,
          false,
          configData?.accountAppUrl
        )
      );
    } else {
      // Helper to handle user routes.
      getCustomRoute(history);
    }
  };

  return (
    <div className="page-signup">
      <Preloader errorTitle="Error loading Sign In page" />
    </div>
  );
};

export default Sign;
