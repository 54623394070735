import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { analytics, db, helpers } from '../../actions';
import { styles } from '../../styles';
import * as routes from '../../constants/routes';
import { APPS_DOMAIN, APPS_DOMAIN_OLD } from '../../constants/auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import Preloader from '../Preloader';
import Verify from '../Verification/Verify';
import ManualVerification from '../Verification/ManualVerification';
import Quiz from '../Verification/Quiz';
import PhoneVerification from '../Verification/PhoneVerification';
import IdScan from '../Verification/IdScan';
import Academy from '../Verification/Academy';
import ActiveDuty from '../Verification/ActiveDuty';
import Commissioned from '../Verification/Commissioned';
import Sign from '../Sign';
import SignOut from '../Sign/SignOut';
import ContactUs from '../StaticPages/ContactUs';
import AlertDialog from '../StaticPages/AlertDialog';
import PrivacyAgreement from '../StaticPages/PrivacyAgreement';
import WhyWeAsk from '../StaticPages/WhyWeAsk';
import MilitaryCredentials from '../MilitaryInfo/MilitaryCredentials';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import * as Sentry from '@sentry/browser';
import PersonalInformation from '../Verification/PersonalInformation';
import LighthouseVerification from '../Verification/LighthouseVerification';
import ErrorBoundary from '../ErrorBoundary';
import Affinity from '../MilitaryInfo/Affinity';
import Affidavit from '../MilitaryInfo/Affidavit';
import CustomContainer from '../CustomContainer';
import AtomicFailureScreen from '../Verification/Atomic/AtomicFailureScreen';
import AtomicFinancial from '../Verification/Atomic';
import WelcomeScreen from '../StaticPages/WelcomeScreen';

const theme = createTheme(styles.theme);
const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

const App = () => {
  const dispatch = useDispatch();

  const [returnType, setReturnType] = useState(null);
  const [initialMaintainanceCheck, setInitialMaintainanceCheck] =
    useState(true);
  const [identifyExecuted, setIdentifyExecuted] = useState(false);

  const staticTextStatus = useSelector((state) => state.textsState.status);
  const errorPageData = useSelector((state) => state.errorState.active);
  const configData = useSelector((state) => state.configState.config);
  const configDataStatus = useSelector((state) => state.configState.status);
  const accountDataStatus = useSelector((state) => state.accountState.status);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const populateSession = useSelector(
    (state) => state.sessionDBState.data.populateSession
  );
  const sessionDbId = useSelector((state) => state.sessionDBState.id);
  const militaryInfoData = useSelector((state) => state.militaryInfoState.data);
  const plansDataStatus = useSelector((state) => state.plansState.status);
  const brandData = useSelector((state) => state.brandState.data);

  const {
    updateSession,
    fetchBrandInfo,
    fetchStaticTexts,
    fetchConfig,
    setErrorPage,
    clearErrorPage,
    setSessionId,
    createSessionId,
  } = db;

  // Execute lightweight identify call on initial app load.
  useEffect(() => {
    if (!identifyExecuted && accountData?.memberId) {
      Sentry.setUser({ id: accountData.memberId });
      analytics.identify();
      setIdentifyExecuted(true);
    }
  }, [accountData, identifyExecuted]);

  useEffect(() => {
    if (
      authUser?.uid &&
      accountData?.memberId &&
      populateSession &&
      sessionDbId
    ) {
      helpers
        .writeSession(
          {
            session_id: sessionDbId,
            user_uid: authUser.uid,
            email: accountData.email,
            memberId: accountData.memberId,
            firstname: accountData.firstname,
            lastname: accountData.lastname,
            phone: accountData.phone ? accountData.phone : null,
            last_update: new Date(),
          },
          sessionDbId
        )
        .then(() => {
          dispatch(updateSession({ populateSession: false }));
        })
        .catch((error) => {
          helpers.handleError(error, dispatch);
        });
    }
    // eslint-disable-next-line
  }, [authUser, accountData?.memberId, sessionDbId])

  useEffect(() => {
    // State the version of app.
    if (process.env.REACT_APP_ENV === 'dev') {
      console.log(process.env.REACT_APP_VERSION);
    }

    // Segment initialization.
    if (
      window.analytics &&
      (typeof window.analytics.initialized === 'undefined' ||
        !window.analytics.initialized)
    ) {
      let segmentKey = process.env.REACT_APP_SEGMENTKEY;
      analytics.load(segmentKey);
    }

    let urlQuery = {};
    window.location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        urlQuery[item.split('=')[0]] = item.split('=')[1];
      });

    if (typeof urlQuery.sessionId !== 'undefined') {
      dispatch(setSessionId(urlQuery.sessionId));
    } else {
      dispatch(updateSession({ newSession: true }));
      dispatch(createSessionId());
    }

    dispatch(
      updateSession({
        idVerification:
          typeof urlQuery.idVerification !== 'undefined'
            ? urlQuery.idVerification
            : false,
        militaryQuestionsVerification:
          typeof urlQuery.militaryQuestionsVerification !== 'undefined'
            ? urlQuery.militaryQuestionsVerification
            : true,
        additionalExternalVerification:
          typeof urlQuery.additionalExternalVerification !== 'undefined'
            ? urlQuery.additionalExternalVerification
            : false,
      })
    );

    // If we have brand_uuid, fetch brand info.
    if (typeof urlQuery.brand_uuid !== 'undefined') {
      dispatch(fetchBrandInfo(null, urlQuery.brand_uuid));
    }
    // If we have providerToken, fetch brand info.
    else if (typeof urlQuery.providerToken !== 'undefined') {
      dispatch(fetchBrandInfo(urlQuery.providerToken));
    }

    if (typeof urlQuery.return_to !== 'undefined') {
      try {
        const decodedReturnTo = decodeURIComponent(urlQuery.return_to);
        const redirectUrl = new URL(decodedReturnTo);
        // Remove url redirect which is not a subdomain of VA.
        if (
          redirectUrl.hostname &&
          !redirectUrl.hostname.endsWith(`.${APPS_DOMAIN}`) &&
          // TODO remove once domains switched.
          !redirectUrl.hostname.endsWith(`.${APPS_DOMAIN_OLD}`)
        ) {
          delete urlQuery.return_to;
        }
      } catch (e) {
        // Unset return_to if non valid url supplied.
        delete urlQuery.return_to;
      }
    }

    // Set return type and source.
    setReturnType(
      typeof urlQuery.providerToken !== 'undefined' &&
        urlQuery.providerToken !== 'vaplatformdefault'
        ? 'widget'
        : 'platform'
    );

    if (urlQuery) {
      dispatch(
        updateSession({
          urlQuery: urlQuery,
          hash: window?.location?.hash,
        })
      );
    }

    // Get all the static texts.
    dispatch(fetchStaticTexts());

    // Get the specific settings of the app.
    dispatch(fetchConfig());
    // eslint-disable-next-line
  }, [])

  const checkMaintenance = () => {
    if (configData?.maintenance?.status) {
      setInitialMaintainanceCheck(false);
      dispatch(
        setErrorPage(
          configData.maintenance.title
            ? configData.maintenance.title
            : 'Maintenance',
          configData.maintenance.description
            ? configData.maintenance.description
            : 'We are currently performing scheduled maintenance operations. Functionality is currently limited. Thank you for your patience and please check back soon.',
          'error'
        )
      );
    } else if (
      !isIE &&
      !configData?.maintenance?.status &&
      !initialMaintainanceCheck
    ) {
      dispatch(clearErrorPage());
    }
  };

  useEffect(() => {
    if (configData?.maintenance?.status) {
      checkMaintenance();
    }
    // eslint-disable-next-line
  }, [configData])

  useEffect(() => {
    if (isIE) {
      dispatch(
        setErrorPage(
          false,
          false,
          'error',
          'To enjoy our website and access the best military discounts, use @chrome @safari, or @mozilla.',
          false,
          false,
          false,
          false,
          false,
          'Wait! Did you know that your browser is likely out of date?'
        )
      );
    }
    // eslint-disable-next-line
  }, [isIE, errorPageData])

  const close = () => {
    window.location.href = configData.platformUrl;
  };

  // Inject Transcend script.
  useEffect(() => {
    if (window.location.hash.includes('skiptranscend')) {
      console.log('Skipped Transcend widget');
      return;
    }

    const exists = document.getElementById('transcend-script');
    if (!exists) {
      const script = document.createElement('script');
      script.id = 'transcend-script';
      const isProd = process.env.REACT_APP_ENV === 'prod';
      script.src = `https://privacy-modules.wesalute.com/cm${!isProd ? '-test' : ''}/408a9d8d-5e3f-4740-bd59-b3ba14eb8230/airgap.js`;
      const scriptNodes = document.getElementsByTagName('script')[0];
      const scriptNode = scriptNodes.length ? scriptNodes[0] : null;
      if (scriptNode) {
        scriptNode.parentNode.insertBefore(script, scriptNode);
      } else {
        document.head.insertBefore(script, null);
      }

      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  if (
    configDataStatus !== 'loaded' &&
    staticTextStatus !== 'loaded' &&
    accountDataStatus !== 'loaded' &&
    (!authUser ||
      (militaryInfoData?.bases &&
        plansDataStatus !== 'loaded' &&
        accountDataStatus !== 'loaded'))
  ) {
    return (
      // If the info is not loaded, show preloader.
      <React.Fragment>
        <CssBaseline />
        <Preloader
          title="Loading app. Please wait."
          errorTitle="Error loading Homepage"
        />
      </React.Fragment>
    );
  } else {
    // Set all the routes after the info is loaded.
    return (
      <React.Fragment>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Header
                close={close}
                showClose={returnType !== 'widget'}
                brandData={brandData ?? null}
              />
              <ErrorBoundary>
                <CustomContainer>
                  {errorPageData && <AlertDialog />}
                  <React.Fragment>
                    <Route exact path={routes.SIGN} render={() => <Sign />} />
                    <Route
                      exact
                      path={routes.SIGN_OUT}
                      render={() => <SignOut />}
                    />
                    <Route
                      exact
                      path={routes.STATIC_PRIVACY_AGREEMENT}
                      render={() => <PrivacyAgreement />}
                    />
                    <Route
                      exact
                      path={routes.STATIC_WHY_WE_ASK}
                      render={() => <WhyWeAsk />}
                    />
                    <Route
                      exact
                      path={routes.WELCOME_SCREEN}
                      render={() => <WelcomeScreen />}
                    />
                    <Route
                      exact
                      path={routes.PERSONAL_INFORMATION}
                      render={() => <PersonalInformation />}
                    />
                    <Route
                      exact
                      path={routes.AFFINITY}
                      render={() => <Affinity />}
                    />
                    <Route
                      exact
                      path={routes.AFFIDAVIT}
                      render={() => <Affidavit />}
                    />
                    <Route
                      exact
                      path={routes.MILITARY_CREDENTIALS}
                      render={() => <MilitaryCredentials />}
                    />
                    <Route
                      exact
                      path={routes.ACTIVE_DUTY}
                      render={() => <ActiveDuty />}
                    />
                    <Route
                      exact
                      path={routes.COMMISSIONED}
                      render={() => <Commissioned />}
                    />
                    <Route
                      exact
                      path={routes.ACADEMY}
                      render={() => <Academy />}
                    />
                    <Route
                      exact
                      path={routes.LIGHTHOUSE_VERIFICATION}
                      render={() => <LighthouseVerification />}
                    />
                    <Route exact path={routes.QUIZ} render={() => <Quiz />} />
                    <Route
                      exact
                      path={routes.PHONE_VERIFICATION}
                      render={() => <PhoneVerification />}
                    />
                    <Route
                      exact
                      path={routes.ID_SCAN}
                      render={() => <IdScan />}
                    />
                    <Route
                      exact
                      path={routes.VERIFY}
                      render={() => <Verify returnType={returnType} />}
                    />
                    <Route
                      exact
                      path={routes.CONTACT_US}
                      render={() => <ContactUs />}
                    />
                    <Route
                      exact
                      path={routes.MANUAL_VERIFICATION}
                      render={() => <ManualVerification />}
                    />
                    <Route
                      exact
                      path={routes.ATOMIC_FINANCIAL_VERIFICATION}
                      render={() => <AtomicFinancial />}
                    />
                    <Route
                      exact
                      path={routes.ATOMIC_FINANCIAL_VERIFICATION_FAILURE}
                      render={() => <AtomicFailureScreen />}
                    />
                  </React.Fragment>
                </CustomContainer>
              </ErrorBoundary>
              <Footer />
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </React.Fragment>
    );
  }
};

export default App;
